import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import StripeCheckout from 'react-stripe-checkout';
import { Row, Col, Button, Alert } from 'react-bootstrap'
import './Home.css'
import { UserContext } from "../../Contexts/UserProvider";
import TrialQuiz from "../TrialQuiz/TrialQuiz";

function Home(props) {
  const { user, loading, refreshUserData } = useContext(UserContext);
  const location = useLocation();
  const [promoCode, setPromoCode] = useState('');
  const [message, setMessage] = useState('');
  const [err, setErr] = useState('');
  const [subscriptions, setSubscriptions] = useState([
    {
      name: "targetedApproach",
      title: "Targeted Approach",
      amount: 1500,
      duration: 6
    },
    {
      name: "thoroughPractice",
      title: "Thorough Practice",
      amount: 2500,
      duration: 6
    }
  ]);

  const navigate = useNavigate();

  const fetchPromo = async (promoCode) => {
    try {
      const response = await axios.get(`https://api.reviseukmla.com/api/v1/subscription/getPromoDuration?promoCode=${promoCode}`);
      if (response.status === 200 && response.data && response.data.data) {
        setSubscriptions(response.data.data.subscriptions);
        setPromoCode(promoCode);
        setErr("");
      }
    } catch (error) {
      setErr(error.response.data.error);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const promo = queryParams.get('promoCode');


    const checkPromoAndNavigate = async () => {
      if (promo && promo.length) {
        // console.log('fetch promo called!')
        // setPromoCode(promo);
        await fetchPromo(promo); // Await the async function
      }
    };

    checkPromoAndNavigate();
  }, [
    location
  ]);

  const handleToken = async (token, subscription, amount) => {
    setMessage("");
    setErr('');
    if (!user) {
      navigate('/login');
      return;
    }

    const authToken = Cookies.get('token');
    try {
      const response = await axios.post('https://api.reviseukmla.com/api/v1/subscription/buySubscription', { token, subscription, amount }, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      });
      // console.log(response.data.status);
      if (response.data.status === "success") {
        setMessage('Payment Successful');
        refreshUserData();
        navigate('/subscriptions');
      } else {
        setErr('Payment Failed');
      }
    } catch (error) {
      setErr('Payment Failed');
    }
  };
  return (
    <div className='home-main'>
      <div className='container'>
        <Row className="justify-content-center mt-4">
          <Col md={6} className="flex-center">
            <h2 className="white-heading"><strong>Question bank designed strictly following the GMC's MLA content map</strong></h2><br />
            <h3 className="white-heading">Only thing that will surprise you is your result!</h3>
            <h1 className="white-heading d-md-none d-lg-none" style={{ fontSize: "60px" }}>Revise UKMLA</h1>
          </Col>
          <Col md={3} className="mb-2 subscription-card-main">
            <div className="card">
              <div className="title">{subscriptions[0].title}</div>
              <div className="content">
                <div className="price">£{subscriptions[0].amount / 100}</div>
                <div className="description">
                  <p>✔ {subscriptions[0].duration} months access</p>
                  <p>✔ 3500 MCQs</p>
                  <p>✔ Covering all areas of clinical practice</p>
                  <p>✔ Detailed analysis of your weak areas</p>
                  <p>✔ All reading material provided</p>
                </div>
              </div>
              {user ? (
                (user.subscriptionExpires < new Date() || user.subscription !== subscriptions[0].name) ? (
                  <Button variant="primary" className='' onClick={() => { props.handleBuyClick(subscriptions[0], promoCode); navigate('/subscriptions') }}>Buy Now</Button>
                ) : (<Button variant="primary" className='' href='/subscriptions'>Start studying</Button>)
              ) : (<Button variant="primary" className='' onClick={() => { props.handleBuyClick(subscriptions[0], promoCode); navigate('/subscriptions') }}>Buy Now</Button>)}
            </div>
          </Col>
          <Col md={3} className="mb-2 subscription-card-main">
            <div className="card">
              <div className="title">{subscriptions[1].title}</div>
              <div className="content">
                <div className="price">£{subscriptions[1].amount / 100}</div>
                <div className="description">
                  <p>✔ {subscriptions[1].duration} months access</p>
                  <p>✔ 6000 MCQs</p>
                  <p>✔ Covering all areas of clinical practice</p>
                  <p>✔ Detailed analysis of your weak areas</p>
                  <p>✔ All reading material provided</p>
                </div>
              </div>
              {user ? (
                (user.subscriptionExpires < new Date() || user.subscription !== subscriptions[1].name) ? (
                  <Button onClick={() => { props.handleBuyClick(subscriptions[1], promoCode); navigate('/subscriptions') }}>Buy Now</Button>)
                  : (<Button variant="primary" className='' href='/subscriptions'>Start studying</Button>)
              ) : (<Button onClick={() => { props.handleBuyClick(subscriptions[1], promoCode); navigate('/subscriptions') }}>Buy Now</Button>)}
            </div>
          </Col>
          <Col md={6}>
            <h1 className="white-heading d-none d-sm-none d-md-block d-lg-block" style={{ fontSize: "60px", transform: "translateY(-15%)" }}>Revise UKMLA</h1>
          </Col>
          <Col md={6}>
            <TrialQuiz />
            {err !== "" ? (
              <Alert key="danger" variant="danger">
                {err}
              </Alert>
            ) : null}
          </Col>
        </Row>
      </div>
    </div >
  )
}

export default Home