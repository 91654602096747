import React, { useState, useRef, useContext, useEffect } from 'react'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Alert } from 'react-bootstrap';
import Cookies from 'js-cookie'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import ReCAPTCHA from "react-google-recaptcha";

import cardLogos from './images/visa-mastercard-american-express-logos.png'
import './CheckoutForm.css';
import { UserContext } from '../../Contexts/UserProvider';

const cardElementOptions = {
    style: {
        base: {
            fontSize: '16px',
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'montserrat, sans-serif',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#9e2146',
        },
    },
};

function CheckoutForm({ amount, handleToken }) {
    const { user, refreshUserData } = useContext(UserContext);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [emailExists, setEmailExists] = useState(false);
    const [validations, setValidations] = useState({
        capital: false,
        small: false,
        number: false,
        special: false,
    });
    const recaptchaRef = useRef();
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [newUser, setNewUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirm: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage('');

        if (!stripe || !elements) {
            return;
        }

        try {
            const { token, error: stripeError } = await stripe.createToken(elements.getElement(CardElement));
            // console.log('Token created:', token);

            if (stripeError) {
                setError(stripeError.message);
                setLoading(false);
                return;
            }
            let response;
            if (!emailExists && !user) {
                if (newUser.password !== newUser.passwordConfirm) {
                    setError('Passwords do not match');
                    setLoading(false);
                    return;
                }
                if (!validations.characters || !validations.capital || !validations.small || !validations.number || !validations.special) {
                    setError('Please choose a strong password');
                    setLoading(false);
                    return;
                }
                response = await axios.post('https://api.reviseukmla.com/api/v1/users/signup', {
                    firstName: newUser.firstName,
                    lastName: newUser.lastName,
                    email: newUser.email,
                    password: newUser.password,
                    passwordConfirm: newUser.passwordConfirm,
                    captcha: captchaValue
                });

                if (response.data.status === 'success') {
                    setMessage('Register successful');
                    Cookies.set("token", response.data.token, { expires: 7 });
                    refreshUserData();
                    // localStorage.removeItem('dataLoaded');
                    // refreshUserData();
                }
            } else if (emailExists && !user) {
                response = await axios.post(
                    "https://api.reviseukmla.com/api/v1/users/login",
                    {
                        email: newUser.email,
                        password: newUser.password,
                        captcha: captchaValue
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response && response.status === 200) {
                    setMessage("Subscription successful.");
                    Cookies.set("token", response.data.token, { expires: 7 });
                    refreshUserData();
                }
            }
            setLoading(true);
            if (response && (response.status === 200 || response.status === 201) || user) {
                await handleToken(token);
            }
        } catch (error) {
            // console.log(error);
            setError(error.response.data.message || 'Subscription failed');
            if (error.response.data.message === 'This Email already exists') {
                setEmailExists(true);
            }
            setNewUser({ ...newUser, password: '', passwordConfirm: '' });
            recaptchaRef.current.reset();
            setCaptchaValue(null);
        }
        setLoading(false);
    };
    const handleChange = (e) => {
        setNewUser({ ...newUser, [e.target.name]: e.target.value });
        if (e.target.name === "password") {
            setValidations({
                characters: e.target.value.length >= 8,
                capital: /[A-Z]/.test(e.target.value),
                small: /[a-z]/.test(e.target.value),
                number: /[0-9]/.test(e.target.value),
                special: /[^A-Za-z0-9]/.test(e.target.value),
            });
        }
    }
    const handleChangePassword = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value });
        if (newUser.password === value) {
            setError("");
        }
        else {
            setError("Passwords do not match");
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            {!user &&
                <>
                    {!emailExists && (
                        <>
                            <Form.Group controlId="firstName" className=' mb-3'>
                                <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    placeholder="Enter your first name"
                                    value={newUser.firstName}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="lastName" className=' mb-3'>
                                <Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    placeholder="Enter your last name"
                                    value={newUser.lastName}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="email" className=' mb-3'>
                                <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={newUser.email}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="password" className=' mb-3'>
                                <Form.Label>Password <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder="Enter your password"
                                    value={newUser.password}
                                    onChange={handleChange}
                                />
                                {newUser.password !== "" && (
                                    <div className="mt-2">
                                        <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                                            <li style={{ color: validations.characters ? 'green' : 'red' }}>
                                                {validations.characters ? '✔' : '✖'} Atleast eight characters
                                            </li>
                                            <li style={{ color: validations.capital ? 'green' : 'red' }}>
                                                {validations.capital ? '✔' : '✖'} Atleast one capital letter
                                            </li>
                                            <li style={{ color: validations.small ? 'green' : 'red' }}>
                                                {validations.small ? '✔' : '✖'} Atleast one small letter
                                            </li>
                                            <li style={{ color: validations.number ? 'green' : 'red' }}>
                                                {validations.number ? '✔' : '✖'} Atleast one number
                                            </li>
                                            <li style={{ color: validations.special ? 'green' : 'red' }}>
                                                {validations.special ? '✔' : '✖'} Atleast one special character
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </Form.Group>
                            <Form.Group controlId="confirmPassword" className=' mb-3'>
                                <Form.Label>Confirm Password <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="password"
                                    name="passwordConfirm"
                                    placeholder="Confirm your password"
                                    value={newUser.confirmPassword}
                                    onChange={handleChangePassword}
                                />
                            </Form.Group>
                        </>
                    )}
                    {emailExists && (
                        <>
                            <h1>You are already a part of UKMLA Family. Please Login</h1>
                            <Form.Group controlId="email" className=' mb-3'>
                                <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={newUser.email}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="password" className=' mb-3'>
                                <Form.Label>Password <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder="Enter your password"
                                    value={newUser.password}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </>
                    )}
                </>
            }
            <Form.Group controlId="cardElement">
                <Form.Label>Card Information <span className="text-danger">*</span>&nbsp;<img src={cardLogos} style={{ height: "20px" }} alt='Visa/Mastercard/American Express' /></Form.Label>
                <CardElement options={cardElementOptions} />
            </Form.Group>
            <Button className='mt-4 mb-4' variant="primary" type="submit" disabled={loading}>
                {loading ? 'Processing...' : `Pay £${amount / 100}`}
            </Button>
            <ReCAPTCHA className='customcaptcha mt-4'
                sitekey="6Le3dhAqAAAAAP9ZLjG_e4iZDZpOIvbnh2Pu4pLQ"
                ref={recaptchaRef}
                onChange={value => setCaptchaValue(value)}
                onExpired={() => setCaptchaValue(null)}
            />
            {error && <Alert variant="danger" className='mb-4'>{error}</Alert>}
            {message && <Alert variant="success" className='mb-4'>{message}</Alert>}
        </Form>
    );
}

export default CheckoutForm